import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { getSkinTreatmentInvoiceData, saveSkinTreatmentPlan, updateSkinTreatmentInvoice } from '../../services/SkinTreatmentService';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { getSkinTreatmentPlanInvoice } from '../../services/SkinTreatmentService';
import { useNavigate } from 'react-router-dom';
import { getPatientCreditList } from '../../services/PatientService';

function EditSkinTreatmentInvoice(props) {

    const { openEditInvoice, setOpenEditInvoice, getTreatmentForPatient, callFrom, setAddSkinTreatmentNots, setOpenConsentForm,
        setAddPostSessionNots, setOpenPhotoUploadDialog, setOpenPreSessionWarningDialog, userData, clinicData, patientId } = props;
    const { register, handleSubmit, reset, getValues, watch, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getTotalPrice, setGetTotalPrice] = useState("");
    const [patientCreditList, setPatientCreditList] = useState(0);
    const [getCreditValue, setCreditValue] = useState(null);
    const [getPatientId, setGetPatientId] = useState(null);
    const navigate = useNavigate()

    const onChangeSession = watch('numberOfSessions');
    const onChangeMultiplayer = watch('multiplayer');
    const onChangeEft = watch('eft');
    const onChangeGiftCard = watch('giftCard');
    const onChangeCash = watch('cash');
    const onChangeTotalAmount = watch('totalPrice');
    const onChangeDiscount = watch('discount');
    const onChangeTotalCreditAmount = watch('patientCredit');

    // useEffect(() => {
    //     calculationOfNumberOfSession();
    // }, [onChangeSession, onChangeMultiplayer]);

    useEffect(() => {
        calculationOfTotalPrice()
    }, [onChangeMultiplayer]);

    // const calculationOfNumberOfSession = () => {
    //     let numberOfSessions = getValues('numberOfSessions');
    //     let multiplayer = getValues('multiplayer');
    //     if (numberOfSessions && multiplayer) {
    //         let totalNumberOfSessions = Number(numberOfSessions) * Number(multiplayer);
    //         setValue("total_session", totalNumberOfSessions);
    //     };
    // };

    const calculationOfTotalPrice = () => {
        let price = getValues('totalPrice');
        let multiplayer = getValues('multiplayer');
        let perUnitPrice = Number(getTotalPrice) / Number(openEditInvoice.invoiceData?.multiplayer);
        if (perUnitPrice && multiplayer) {
            let totalPrice = (Number(perUnitPrice) * Number(multiplayer)).toFixed(2);
            setValue("totalPrice", totalPrice);
        };
    };

    useEffect(() => {
        if (openEditInvoice.action === true) {
            getInvoiceData()
        }
    }, [openEditInvoice.action === true]);

    useEffect(() => {
        totalCalculation();
    }, [onChangeEft, onChangeGiftCard, onChangeCash, onChangeDiscount, onChangeTotalAmount, onChangeTotalCreditAmount, openEditInvoice.action === true]);

    const totalCalculation = () => {
        let eftValue = getValues('eft');
        let giftCardValue = getValues('giftCard');
        let cashValue = getValues('cash');
        let totalAmount = getValues('totalPrice');
        let discountValue = getValues('discount');
        let totalCreditAmount = getValues('patientCredit');
        let totalValue = Number(totalAmount) - (Number(eftValue) + Number(giftCardValue) + Number(cashValue) + Number(totalCreditAmount));
        setValue("remainingAmount", Number(totalValue) - Number(discountValue));
        setCreditValue(totalCreditAmount);
    };

    const getInvoiceData = async () => {
        await getSkinTreatmentInvoiceData(openEditInvoice.invoiceData.invoiceId)
            .then((resp) => {
                setGetTotalPrice(resp.data.totalPrice)
                getPatientCreditListData(resp.data.patientId);
                reset({
                    treatmentName: openEditInvoice.invoiceData.treatmentName, treatmentArea: openEditInvoice.invoiceData.treatmentArea, patientId: resp.data.patientId, generatedBy: resp.data.generatedBy,
                    total_session: openEditInvoice.invoiceData.numberOfSessions, multiplayer: openEditInvoice.invoiceData.multiplayer, productOrMachineUsed: openEditInvoice.invoiceData.productOrMachineUsed,
                    discount: resp.data.discount, totalPrice: resp.data.totalPrice
                });
                setValue("eft", resp.data.eft === null ? 0 : resp.data.eft);
                setValue("giftCard", resp.data.giftCard === null ? 0 : resp.data.giftCard);
                setValue("cash", resp.data.cash === null ? 0 : resp.data.cash);
                setValue("patientCredit", resp.data.patientCredit === null ? 0 : resp.data.patientCredit);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientCreditListData = (patientId) => {
        let payload = {
            patientId: patientId,
            // onlyNotUsed: false
        }
        getPatientCreditList(payload)
            .then((resp) => {
                if (resp.data.length) {
                    if (resp.data[0].balance < 0) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "Customer has an outstanding balance. Please check customer credit.",
                        })
                        setPatientCreditList(0);
                    } else {
                        setPatientCreditList(resp.data[0].balance);
                    }
                } else {
                    setPatientCreditList(0);
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const updateInvoice = async (formData) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait....",
        })
        if (openEditInvoice.invoiceData?.numberOfSessions !== 1 && openEditInvoice.invoiceData?.remainingSessions !== 0) {
            let obj = {
                ...formData,
                total_session: formData.multiplayer,
                invoiceId: openEditInvoice.invoiceData.invoiceId
            }
            updateSkinTreatmentInvoice(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSkinTreatmentPlanInvoice(openEditInvoice.invoiceData.invoiceId)
                        .then((resp) => {
                            const newTab = window.open("", "_blank")
                            newTab.document.write(resp.data)
                        })
                        .catch((error) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data,
                            })
                        })
                    reset({ treatmentName: "", treatmentArea: "", productOrMachineUsed: "", numberOfSessions: "", patientId: "", discount: "", price: "", total_price: "", total_session: "" })
                    if (callFrom === "skin-treatment-invoice") {
                        getTreatmentForPatient(openEditInvoice.invoiceData.clinicId, openEditInvoice.invoiceData.patientId);
                        setOpenEditInvoice({ action: false, invoiceData: null });
                    } else {
                        setAddSkinTreatmentNots({ action: false, patientData: null });
                        setOpenConsentForm({ action: false, patientData: null });
                        setAddPostSessionNots({ action: false, patientData: null, note: null });
                        setOpenPhotoUploadDialog({ action: false, treatmentData: null });
                        setOpenPreSessionWarningDialog({ action: false, patientData: null });
                        setOpenEditInvoice({ action: false, invoiceData: null });
                        navigate("/patient-list/view-patient-details", { state: { patientData: openEditInvoice.invoiceData } });
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            let obj = {
                generatedBy: formData.generatedBy,
                "treatmentName": formData.treatmentName,
                "treatmentArea": formData.treatmentArea,
                "productOrMachineUsed": formData.productOrMachineUsed,
                "numberOfSessions": Number(formData.multiplayer),
                "patientId": formData.patientId,
                "clinicId": clinicDetails.clinicId,
                "orgId": userDetails.orgId,
                "discount": formData.discount,
                "injectorId": userDetails.userId,
                multiplayer: Number(formData.multiplayer),
                cash: Number(formData.cash),
                giftCard: Number(formData.giftCard),
                eft: Number(formData.eft),
                total_session: formData.multiplayer,
                total_price: formData.totalPrice,
                invoiceId: openEditInvoice.invoiceData.invoiceId,
                skinTreatmentPlanId: openEditInvoice.invoiceData.skinTreatmentPlanId,
                remainingSessions: Number(formData.multiplayer) - 1,
                "patientSignature": "",
                consentFormId: "SKC_" + openEditInvoice.invoiceData.skinTreatmentPlanId.split("_")[1],
                patientCredit: formData.patientCredit,
            };
            saveSkinTreatmentPlan(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Updated",
                    })
                    getSkinTreatmentPlanInvoice(openEditInvoice.invoiceData.invoiceId)
                        .then((resp) => {
                            const newTab = window.open("", "_blank");
                            newTab.document.write(resp.data);
                            if (callFrom === "skin-treatment-invoice") {
                                getTreatmentForPatient(openEditInvoice.invoiceData.clinicId, openEditInvoice.invoiceData.patientId);
                                setOpenEditInvoice({ action: false, invoiceData: null });
                            } else {
                                setAddSkinTreatmentNots({ action: false, patientData: null });
                                setOpenConsentForm({ action: false, patientData: null });
                                setAddPostSessionNots({ action: false, patientData: null, note: null });
                                setOpenPhotoUploadDialog({ action: false, treatmentData: null });
                                setOpenPreSessionWarningDialog({ action: false, patientData: null });
                                navigate("/patient-list/view-patient-details", { state: { patientData: openEditInvoice.invoiceData } });
                                setOpenEditInvoice({ action: false, invoiceData: null });
                            }
                            reset({ treatmentName: "", treatmentArea: "", productOrMachineUsed: "", numberOfSessions: "", patientId: "", discount: "", price: "", total_price: "", total_session: "" })
                        })
                        .catch((error) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data,
                            })
                        })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        }
    };

    const cancel = () => {
        if (callFrom === "skin-treatment-invoice") {
            setOpenEditInvoice({ action: false, invoiceData: null })
            setOpenEditInvoice({ action: false, invoiceData: null });
        } else {
            setAddSkinTreatmentNots({ action: false, patientData: null });
            setOpenConsentForm({ action: false, patientData: null });
            setAddPostSessionNots({ action: false, patientData: null, note: null });
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
            setOpenPreSessionWarningDialog({ action: false, patientData: null });
            setOpenEditInvoice({ action: false, invoiceData: null });
        }
    };

    return (
        <div>
            <material.Dialog open={openEditInvoice.action} fullWidth maxWidth="lg" hideBackdrop>
                <material.DialogTitle>Edit Invoice</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-4">
                            <material.TextField
                                error={errors.patientId?.type === "required"}
                                id="name"
                                margin="dense"
                                fullWidth
                                required
                                variant="standard"
                                label="Patient Id"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("patientId")}
                            />
                        </div>
                        <div className="col-4">
                            <material.TextField
                                error={errors.treatmentName?.type === "required"}
                                id="treatmentName"
                                margin="dense"
                                fullWidth
                                required
                                variant="standard"
                                label="Treatment Name"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("treatmentName")}
                            />
                        </div>
                        <div className="col-4">
                            <material.TextField
                                error={errors.treatmentArea?.type === "required"}
                                id="treatment"
                                fullWidth
                                margin="dense"
                                required
                                variant="standard"
                                label="Treatment Area"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("treatmentArea")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Total Session"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("total_session")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Number of Packages"
                                variant="standard"
                                type="number"
                                size="small"
                                InputProps={{ readOnly: openEditInvoice.invoiceData?.numberOfSessions === 1 && openEditInvoice.invoiceData?.remainingSessions === 0 ? false : true }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("multiplayer")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Total Price"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("totalPrice")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                error={errors.productOrMachineUsed?.type === "required"}
                                margin="dense"
                                label="Product/Machine"
                                variant="standard"
                                type="text"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("productOrMachineUsed")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                error={errors.discount?.type === "required"}
                                required
                                margin="dense"
                                label="Discount"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                {...register("discount")}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                id="generatedBy"
                                margin="dense"
                                required
                                fullWidth
                                variant="standard"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                label="Sales Account"
                                {...register("generatedBy")}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("giftCard", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Gift Card"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("eft", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="EFT"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("cash", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Cash"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("remainingAmount")}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Remaining Amount"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("patientCredit", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Credit"
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                defaultValue={0}
                                disabled={patientCreditList === 0}
                            />
                            {(getCreditValue > patientCreditList) && (
                                <h6 className="text-danger">Maximum credit available {patientCreditList}</h6>
                            )}
                            <h6>Available Balance : {patientCreditList}</h6>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={cancel}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(updateInvoice)}>Update</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(EditSkinTreatmentInvoice);