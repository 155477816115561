import React, { useState, useRef, useEffect } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewPatientProfile from '../../dialog/ViewPatientProfile';
import SkinTreatmentNote from '../../dialog/SkinTreatmentNote';
import PostSessionNote from '../../dialog/PostSessionNote';
import ConsentFormCP from '../consent-form/ConsentFormCP';
import ConsentFormIPL from '../consent-form/ConsentFormIPL';
import ConsentFormLT from '../consent-form/ConsentFormLT';
import ConsentFormPDT from '../consent-form/ConsentFormPDT';
import PreSessionWarning from '../../dialog/PreSessionWarning';
import Hydrafacial from '../consent-form/Hydrafacial';
import Microneedling from '../consent-form/Microneedling';
import { getSingleSkinTreatmentPlanForPatient, getSkinTreatmentImageList, rejectSkinTreatmentPlan } from '../../../services/SkinTreatmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import moment from 'moment';
import Navbar from '../../navbar/Navbar';
import AddAndUploadPhotoDialog from './AddAndUploadPhotoDialog';
import { connect } from 'react-redux';
import { rootApiUrl } from '../../../environment/Environment';
import { socket } from '../../../socket/Socket';
import ViewProtocolList from '../clinic-config/protocol/ViewProtocolList';

function SkinTreatmentDetails(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openPatientProfile, setOpenPatientProfile] = useState({ action: false, patientData: null });
    const [addSkinTreatmentNots, setAddSkinTreatmentNots] = useState({ action: false, patientData: null });
    const [addPostSessionNots, setAddPostSessionNots] = useState({ action: false, patientData: null, note: null });
    const [openPreSessionWarningDialog, setOpenPreSessionWarningDialog] = useState({ action: false, patientData: null });
    const [openConsentForm, setOpenConsentForm] = useState({ action: false, patientData: null });
    const [openDialog, setOpenDialog] = useState({ action: false, treatmentData: null });
    const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState({ action: false, treatmentData: null });
    const [imageListData, setImageListData] = useState([]);
    const [patientSkinTreatmentPlanData, setPatientSkinTreatmentPlanData] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openViewProtocolList, setOpenViewProtocolList] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const patientDataDetails = location.state ? location.state.patientData : "";
    localStorage.setItem("menuName", "Skin Treatment Details");

    socket.on("new-concent-added", (resp) => {
        getSkinTreatmentPlanForPatient();
        setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": "Consent form added successfully",
        })
    });

    socket.on("new-photo-added", (resp) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Photo Uploaded",
        })
        getImageList();
    });

    const goBack = () => {
        navigate("/patient-list")
    };

    useEffect(() => {
        getImageList();
        getSkinTreatmentPlanForPatient();
    }, []);

    const getSkinTreatmentPlanForPatient = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        const payload = {
            clinicId: patientDataDetails.clinicId,
            patientId: patientDataDetails.patientId,
            skinTreatmentPlanId: patientDataDetails.skinTreatmentPlanId
        }
        getSingleSkinTreatmentPlanForPatient(payload)
            .then((resp) => {
                setPatientSkinTreatmentPlanData(resp.data);
                if (resp.data.remainingSessions !== 0) {
                    if (resp.data.consentFormId === "" || resp.data.consentFormId === null) {
                        setOpenConsentForm({ action: true, patientData: resp.data });
                    } else {
                        setOpenConsentForm({ action: true, patientData: resp.data });
                    };
                }
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Fetching completed",
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getImageList = async () => {
        let payload = {
            sessionId: patientDataDetails.skinTreatmentSessionId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            typeOfImage: "skin_before",
            patientId: patientDataDetails.patientId
        }
        await getSkinTreatmentImageList(payload)
            .then((resp) => {
                setImageListData(resp.data)
            })
            .catch((error) => {

            })
    };

    const addPhoto = () => {
        setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
    };

    const addConcent = () => {
        setOpenConsentForm({ action: true, patientData: patientDataDetails });
    };

    const viewConcent = () => {
        setOpenConsentForm({ action: true, patientData: patientDataDetails })
    };

    const addTreatmentNotes = () => {
        setAddSkinTreatmentNots({ action: true, patientData: patientDataDetails })
    };

    const viewPatientProfile = () => {
        setOpenPatientProfile({ action: true, patientData: patientDataDetails })
    };

    const postTreatmentNote = () => {
        setAddPostSessionNots({ action: true, patientData: patientDataDetails, note: null })
    };

    const usedSession = () => {
        setOpenPreSessionWarningDialog({ action: true, patientData: patientDataDetails })
    };

    const rejectRecord = () => {
        setOpenDialog({ action: true, treatmentData: patientDataDetails })
    };

    const addConcentUsingSecondary = () => {
        const consentData = {
            treatmentType: "skin-treatment",
            patientData: patientDataDetails
        }
        socket.emit("add-concent-kiosk", consentData);
    };

    const addPhotoUsingSecondary = () => {
        const consentData = {
            treatmentType: "skin-treatment",
            patientData: patientSkinTreatmentPlanData
        }
        socket.emit("add-photo-kiosk", consentData);
    };

    const viewProtocol = () => {
        setOpenViewProtocolList(true)
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-12'>
                        {patientSkinTreatmentPlanData?.remainingSessions === 0 ? (
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={goBack}>Back</material.Button>
                            </span>
                        ) : (
                            <span className='float-end'>
                                {patientSkinTreatmentPlanData?.consentFormId === "" || patientSkinTreatmentPlanData?.consentFormId === null ? (
                                    <>
                                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addConcentUsingSecondary}>Add Consent Using Secondary</material.Button>
                                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addConcent}>Add Consent</material.Button>
                                    </>
                                ) : (
                                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={viewConcent}>View Consent</material.Button>
                                )}
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={viewProtocol}>View Protocol</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addPhoto}>Add Photo</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addTreatmentNotes}>Settings</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={postTreatmentNote}>Post Session Notes</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' color='success' onClick={usedSession} hidden={patientDataDetails.state === "REJECTED"}>Finalise</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' color='error' onClick={rejectRecord} hidden={patientDataDetails.state === "REJECTED"}>Reject</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={viewPatientProfile}>View Patient Profile</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={goBack}>Back</material.Button>
                            </span>
                        )}
                    </div>
                </div>
                <material.Paper sx={{ p: 4, mt: 2, pb: 6 }} elevation={3}>
                    <div className='row ms-2'>
                        <div className='col-6 mt-2'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='fw-bold'>
                                        <td>Treatment Name</td>
                                        <td>{patientDataDetails.treatmentName}</td>
                                    </tr>
                                    <tr className='fw-bold'>
                                        <td>Treatment Area</td>
                                        <td>{patientDataDetails.treatmentArea}</td>
                                    </tr>
                                    <tr className='fw-bold'>
                                        <td>Number of Sessions</td>
                                        <td>{patientDataDetails.totalNoOfSession}</td>
                                    </tr>
                                    <tr className='fw-bold'>
                                        <td>Remaining of Sessions</td>
                                        <td>{patientDataDetails.remainingSessions}</td>
                                    </tr>
                                    <tr className='fw-bold'>
                                        <td>Product/Machine</td>
                                        <td>{patientDataDetails.productOrMachineUsed}</td>
                                    </tr>
                                    <tr className='fw-bold'>
                                        <td>Date Time</td>
                                        <td>{moment().format("DD-MM-YYYY, HH.mm A")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-6">
                            <span>
                                {imageListData.length ? imageListData.map((ele, i) => (
                                    <img
                                        key={i}
                                        src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                        height={200} width="30%"
                                        style={{ margin: 3 }}
                                    />
                                )) : ""}
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </div>
            <ViewPatientProfile
                openPatientProfile={openPatientProfile}
                setOpenPatientProfile={setOpenPatientProfile}
            />
            <SkinTreatmentNote
                addSkinTreatmentNots={addSkinTreatmentNots}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                callFrom="skin_treatment_details"
            />
            <PostSessionNote
                addPostSessionNots={addPostSessionNots}
                setAddPostSessionNots={setAddPostSessionNots}
                callFrom="skin_treatment_details"
            />
            <PreSessionWarning
                openPreSessionWarningDialog={openPreSessionWarningDialog}
                setOpenPreSessionWarningDialog={setOpenPreSessionWarningDialog}
                callFrom="skin_treatment_details"
            />
            {openConsentForm.patientData?.treatmentName.toLowerCase().includes("chemical") ? (
                <ConsentFormCP
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("ipl") ? (
                <ConsentFormIPL
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("pdt") ? (
                <ConsentFormPDT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("laser") ? (
                <ConsentFormLT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("aqua") ? (
                <Hydrafacial
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("microneedling") ? (
                <Microneedling
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    patientDataDetails={patientDataDetails}
                />
            ) : null}
            <WarningDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />
            <AddAndUploadPhotoDialog
                openPhotoUploadDialog={openPhotoUploadDialog}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                getImageList={getImageList}
                callFrom="skin_treatment_details"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewProtocolList
                openViewProtocolList={openViewProtocolList}
                setOpenViewProtocolList={setOpenViewProtocolList}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
            />
        </div>
    );
}

const WarningDialog = (props) => {

    const navigate = useNavigate();
    const { openDialog, setOpenDialog } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const cancel = () => {
        setOpenDialog({ action: false, treatmentData: null })
    };

    const rejectRecord = async () => {
        let obj = {
            invoiceId: openDialog.treatmentData.invoiceId,
            skinTreatmentPlanId: openDialog.treatmentData.skinTreatmentPlanId
        }
        await rejectSkinTreatmentPlan(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                navigate(-1)
                setOpenDialog({ action: false, treatmentData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <material.Dialog open={openDialog.action} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogContent >
                    <material.Typography>Are you sour you want to reject this treatment ?</material.Typography>
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' size='small' onClick={cancel}>No</material.Button>
                        <material.Button variant="contained" size='small' color='success' onClick={rejectRecord}>Yes</material.Button>
                    </span>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SkinTreatmentDetails);