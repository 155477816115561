import React, { useEffect, useState } from "react";
import { material } from "../../library/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getClinicAction } from "../../store/action/Action";
import Navbar from "../navbar/Navbar";
import ColumnChart from "../../shared/reporting/ColumnChart";
import {
    getDataForRollingOneMonthReportByClinicId,
    getAverageSalesByCategoryPerPatient,
    getMonthSalesByCategoryAndTreatment,
    getPatientCountsByPeriodStatus,
    getSalesPercentageByTreatment,
} from "../../services/reporting-service/ReportingService";
import TableWidgets from "../../shared/reporting/TableWidgets";
import NewPatientCount from "../../shared/reporting/NewPatientCount";
import SalesAverageByPerPatient from "../../shared/reporting/SalesAverageByPerPatient";
import Snackbar from "../toastrmessage/Snackbar";
import { Chart } from "react-google-charts";

function Dashboard(props) {
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const navigate = useNavigate();
    localStorage.setItem("menuName", "Dashboard");
    const [monthlyTreatmentData, setMonthlyTreatmentData] = useState(null);
    const [tableWidgetsData, setTableWidgetsData] = useState(null);
    const [patientCountData, setPatientCountData] = useState(null);
    const [averageSalesData, setAverageSalesData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [columnChartData, setColumnChartData] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedTreatmentType, setSelectedTreatmentType] = useState(null);
    const [selectedPreviousYearMonth, setSelectedPreviousYearMonth] =
        useState(null);
    const [
        selectedPreviousYearTreatmentType,
        setSelectedPreviousYearTreatmentType,
    ] = useState(null);

    useEffect(() => {
        getColumnChartData();
        getMonthlyTreatmentDataData();
        getTableWidgetsData();
        getPatientCountsData();
        getAverageSalesByPerPatientData();
    }, []);

    const getColumnChartData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId:
                userDetails.role.includes("MANAGER") ||
                    userDetails.role.includes("ADMIN")
                    ? null
                    : userDetails.userId,
        };
        await getDataForRollingOneMonthReportByClinicId(payload)
            .then((resp) => {
                setColumnChartData(resp.data.formattedData);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const getMonthlyTreatmentDataData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId:
                userDetails.role.includes("MANAGER") ||
                    userDetails.role.includes("ADMIN")
                    ? null
                    : userDetails.userId,
        };
        await getSalesPercentageByTreatment(payload)
            .then((resp) => {
                setMonthlyTreatmentData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const getTableWidgetsData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId:
                userDetails.role.includes("MANAGER") ||
                    userDetails.role.includes("ADMIN")
                    ? null
                    : userDetails.userId,
        };
        await getMonthSalesByCategoryAndTreatment(payload)
            .then((resp) => {
                setTableWidgetsData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const getPatientCountsData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId:
                userDetails.role.includes("MANAGER") ||
                    userDetails.role.includes("ADMIN")
                    ? null
                    : userDetails.userId,
        };
        await getPatientCountsByPeriodStatus(payload)
            .then((resp) => {
                setPatientCountData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const getAverageSalesByPerPatientData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId:
                userDetails.role.includes("MANAGER") ||
                    userDetails.role.includes("ADMIN")
                    ? null
                    : userDetails.userId,
        };
        await getAverageSalesByCategoryPerPatient(payload)
            .then((resp) => {
                setAverageSalesData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const handleMonthClick = (monthIndex, treatmentType) => {
        setSelectedMonth(monthIndex);
        setSelectedTreatmentType(treatmentType);
    };

    const getMonthlyData = () => {
        const chartData = [
            [
                "Month",
                "Total Net Sales",
                "Skin Treatment",
                "Cosmetic Injectable",
                "Other Product Sales",
            ],
        ];
        monthlyTreatmentData?.currentFinancialYear?.monthlyDivision.forEach(
            (monthData, index) => {
                chartData.push([
                    monthData.month,
                    monthData.totalNetSales,
                    monthData.totalSkinTreatmentSales,
                    monthData.totalCosmeticInjectableSales,
                    monthData.totalOtherProductSales,
                ]);
            }
        );
        return chartData;
    };

    const getTreatmentData = (monthIndex) => {
        const monthData =
            monthlyTreatmentData.currentFinancialYear.monthlyDivision[monthIndex];
        const chartData = [["Treatment", "Net Sales"]];
        let colors = [];

        if (selectedTreatmentType === "Skin") {
            monthData.skinTreatmentBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#f39c12");
            });
        } else if (selectedTreatmentType === "Cosmetic") {
            monthData.cosmeticTreatmentBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#2980b9");
            });
        } else if (selectedTreatmentType === "Other") {
            monthData.otherProductBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#2ecc71");
            });
        }
        return { chartData, colors };
    };

    const handleClickForPreviousYear = (monthIndex, treatmentType) => {
        setSelectedPreviousYearMonth(monthIndex);
        setSelectedPreviousYearTreatmentType(treatmentType);
    };

    const getPreviousYearMonthlyData = () => {
        const chartData = [
            [
                "Month",
                "Total Net Sales",
                "Skin Treatment",
                "Cosmetic Injectable",
                "Other Product",
            ],
        ];
        monthlyTreatmentData?.previousFinancialYear?.monthlyDivision.forEach(
            (monthData, index) => {
                chartData.push([
                    monthData.month,
                    monthData.totalNetSales,
                    monthData.totalSkinTreatmentSales,
                    monthData.totalCosmeticInjectableSales,
                    monthData.totalOtherProductSales,
                ]);
            }
        );
        return chartData;
    };

    const getPreviousYearTreatmentData = (monthIndex) => {
        const monthData =
            monthlyTreatmentData.previousFinancialYear.monthlyDivision[monthIndex];
        const chartData = [["Treatment", "Net Sales"]];
        let colors = [];

        if (selectedPreviousYearTreatmentType === "Skin") {
            monthData.skinTreatmentBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#f39c12");
            });
        } else if (selectedPreviousYearTreatmentType === "Cosmetic") {
            monthData.cosmeticTreatmentBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#2980b9");
            });
        } else if (selectedPreviousYearTreatmentType === "Other") {
            monthData.otherProductBreakdown.forEach((treatment, idx) => {
                chartData.push([treatment.treatmentName, treatment.netSales]);
                colors.push("#2ecc71");
            });
        }
        return { chartData, colors };
    };
   
    return (
        <div className="container-fluid" style={{ marginTop: "6%" }}>
            <Navbar />
            {/* {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                    }}
                >
                    <material.CircularProgress color="secondary" />
                </div>
            ) : ( */}
                <div className="row pb-3">
                    <div className="col-lg-12">
                        <material.Paper elevation={3}>
                            {columnChartData !== null ? (
                                <ColumnChart columnChartData={columnChartData} />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            <div>
                                {selectedMonth === null ? (
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="400px"
                                        data={getMonthlyData()}
                                        options={{
                                            title: "Current Financial Year Monthly Sales Overview",
                                            hAxis: { title: "Month" },
                                            vAxis: { title: "Net Sales" },
                                            legend: { position: "top" },
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: "select",
                                                callback: ({ chartWrapper }) => {
                                                    const chart = chartWrapper.getChart();
                                                    const selection = chart.getSelection();
                                                    if (selection.length > 0) {
                                                        const monthIndex = selection[0].row;
                                                        const column = selection[0].column;

                                                        if (column === 2) {
                                                            handleMonthClick(monthIndex, "Skin");
                                                        } else if (column === 3) {
                                                            handleMonthClick(monthIndex, "Cosmetic");
                                                        } else if (column === 4) {
                                                            handleMonthClick(monthIndex, "Other");
                                                        }
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                ) : (
                                    <div>
                                        <material.Button
                                            variant="contained"
                                            sx={{ textTransform: "none", m: 2 }}
                                            size="small"
                                            startIcon={<material.ReplyIcon />}
                                            onClick={() => setSelectedMonth(null)}
                                        >
                                            Back
                                        </material.Button>
                                        <Chart
                                            chartType="ColumnChart"
                                            width="100%"
                                            height="400px"
                                            data={getTreatmentData(selectedMonth).chartData}
                                            options={{
                                                title: `Treatment Breakdown for ${monthlyTreatmentData.currentFinancialYear.monthlyDivision[selectedMonth].month} (${selectedTreatmentType})`,
                                                hAxis: { title: "Treatment" },
                                                vAxis: { title: "Net Sales" },
                                                legend: { position: "none" },
                                                colors: getTreatmentData(selectedMonth).colors,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            <div>
                                {selectedPreviousYearMonth === null ? (
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="400px"
                                        data={getPreviousYearMonthlyData()}
                                        options={{
                                            title: "Previous Year Monthly Sales Overview",
                                            hAxis: { title: "Month" },
                                            vAxis: { title: "Net Sales" },
                                            legend: { position: "top" },
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: "select",
                                                callback: ({ chartWrapper }) => {
                                                    const chart = chartWrapper.getChart();
                                                    const selection = chart.getSelection();
                                                    if (selection.length > 0) {
                                                        const monthIndex = selection[0].row;
                                                        const column = selection[0].column;

                                                        if (column === 2) {
                                                            handleClickForPreviousYear(monthIndex, "Skin");
                                                        } else if (column === 3) {
                                                            handleClickForPreviousYear(
                                                                monthIndex,
                                                                "Cosmetic"
                                                            );
                                                        } else if (column === 4) {
                                                            handleClickForPreviousYear(monthIndex, "Other");
                                                        }
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                ) : (
                                    <div>
                                        <material.Button
                                            variant="contained"
                                            sx={{ textTransform: "none", m: 2 }}
                                            size="small"
                                            startIcon={<material.ReplyIcon />}
                                            onClick={() => setSelectedPreviousYearMonth(null)}
                                        >
                                            Back
                                        </material.Button>
                                        <Chart
                                            chartType="ColumnChart"
                                            width="100%"
                                            height="400px"
                                            data={
                                                getPreviousYearTreatmentData(selectedPreviousYearMonth)
                                                    .chartData
                                            }
                                            options={{
                                                title: `Treatment Breakdown for ${monthlyTreatmentData.previousFinancialYear.monthlyDivision[selectedPreviousYearMonth].month} (${selectedPreviousYearTreatmentType})`,
                                                hAxis: { title: "Treatment" },
                                                vAxis: { title: "Net Sales" },
                                                legend: { position: "none" },
                                                colors: getPreviousYearTreatmentData(
                                                    selectedPreviousYearMonth
                                                ).colors,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            {/* {pieChartData !== null ? (
                                <PieChart
                                    pieChartData={pieChartData?.financialYear}
                                    title="Net Sales of This Financial Year"
                                />
                            ) : null} */}
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            {tableWidgetsData !== null ? (
                                <TableWidgets tableWidgetsData={tableWidgetsData} />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {patientCountData !== null ? (
                                <NewPatientCount patientCountData={patientCountData} />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {averageSalesData !== null ? (
                                <SalesAverageByPerPatient averageSalesData={averageSalesData} />
                            ) : null}
                        </material.Paper>
                    </div>
                </div>
            {/* )} */}
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClinicDetails: (data) => {
            dispatch(getClinicAction(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
