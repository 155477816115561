import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Appointment.css";
import Navbar from '../../navbar/Navbar';
import CreateAppointment from './CreateAppointment';
import { checkInjectorAvailability, getAllScheduleByClinicId, getInjectorListByClinicId } from '../../../services/AppointmentService';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import ViewAppointment from './ViewAppointment';
import ViewTodayScheduleData from '../../dialog/ViewTodayScheduleData';
import AddBreakHourDialog from './AddBreakHourDialog';

function Appointment(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        start: "",
        end: "",
        patientName: "",
        purpose: "",
        injectorId: "",
        bookingStatus: "NOT_CONFIRMED",
    });
    const [injectorData, setInjectorData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [injectorAvailability, setInjectorAvailability] = useState({});
    const [openViewAppointment, setOpenViewAppointment] = useState(false);
    const [openViewSchedule, setOpenViewSchedule] = useState({
        action: false,
        injectorData: null,
    });
    const [openAddBreakDialog, setOpenAddBreakDialog] = useState(false);

    const localizer = momentLocalizer(moment);

    useEffect(() => {
        getAllInjectorByClinic()
        checkingInjectorAvailability()
        getAllScheduleData()
    }, []);

    const getAllInjectorByClinic = async () => {
        await getInjectorListByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                let data = resp.data.map((ele) => {
                    return {
                        resourceId: ele.userId,
                        resourceTitle: ele.fullName,
                    };
                });
                setInjectorData(data);
                setIsLoading(false);
            })
            .catch((error) => {

            })
    };

    const getAllScheduleData = async () => {
        await getAllScheduleByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                let changeData = resp.data.map((ele) => ({
                    id: ele.Id,
                    title: ele.title + " - " + ele.PatientName,
                    start: moment(ele.start, "YYYY-MM-DD HH:mm").toDate(),
                    end: moment(ele.end, "YYYY-MM-DD HH:mm").toDate(),
                    resourceId: ele.InjectorId,
                    resource: ele,
                    className: `rbc-event-${ele.bookingStatus.replace(" ", "_")}`,
                }))
                setEvents(changeData)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const checkingInjectorAvailability = async () => {
        const payload = {
            "orgId": userDetails.orgId,
            "clinicId": clinicDetails.clinicId,
        }
        await checkInjectorAvailability(payload)
            .then((resp) => {
                setInjectorAvailability(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setOpenViewAppointment(true);
    };

    const slotPropGetter = (date, resourceId) => {
        const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
        const availability = injectorAvailability[resourceId]?.[dayOfWeek];
        const leaveDates = injectorAvailability[resourceId]?.leaveDates || [];
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const timeInMinutes = hours * 60 + minutes; // Convert current time to minutes

        // Gray out past dates
        if (moment(date).isBefore(moment(), 'day')) {
            return {
                style: {
                    backgroundColor: '#d3d3d3', // Grayed out for past dates
                    pointerEvents: 'none',
                    opacity: 0.7,
                },
            };
        }

        // Check if the date is a leave date
        const leaveDate = leaveDates.find(ld => ld.date === formattedDate);
        if (leaveDate) {
            const leaveStartMinutes = leaveDate.start * 60;
            const leaveEndMinutes = leaveDate.end * 60;
            if (timeInMinutes >= leaveStartMinutes && timeInMinutes < leaveEndMinutes) {
                return {
                    style: {
                        backgroundColor: '#d3d3d3', // Grayed out for leave times
                        pointerEvents: 'none',
                        opacity: 0.7,
                    },
                };
            }
        }

        // If `availability` is undefined, treat the entire day as unavailable
        if (!availability || availability.unavailable) {
            return {
                style: {
                    backgroundColor: '#d3d3d3', // Grayed out for entire day
                    pointerEvents: 'none',
                    opacity: 0.7,
                },
            };
        }

        // Check if the current time falls within the available hours
        const startTime = moment(availability.start, 'HH:mm:ss');
        const endTime = moment(availability.end, 'HH:mm:ss');
        if (timeInMinutes < startTime.hours() * 60 + startTime.minutes() ||
            timeInMinutes >= endTime.hours() * 60 + endTime.minutes()) {
            return {
                style: {
                    backgroundColor: '#e0e0e0',
                    pointerEvents: 'none',
                    opacity: 0.5,
                },
            };
        }

        return {};
    };
    const handleSelectSlot = (slotInfo) => {
        const dayOfWeek = slotInfo.start.getDay();
        const availability = injectorAvailability[slotInfo.resourceId]?.[dayOfWeek];
        const leaveDates = injectorAvailability[slotInfo.resourceId]?.leaveDates || [];
        const formattedDate = moment(slotInfo.start).format('YYYY-MM-DD');
        const hours = slotInfo.start.getHours();
        const minutes = slotInfo.start.getMinutes();
        const timeInMinutes = hours * 60 + minutes;

        // Prevent creating a new event on past dates
        if (moment(slotInfo.start).isBefore(moment(), 'day')) {
            return; // Do nothing
        }

        // Prevent creating a new event if the time falls within any leave time range
        const leaveDate = leaveDates.find(ld => ld.date === formattedDate);
        if (leaveDate) {
            const leaveStartMinutes = leaveDate.start * 60;
            const leaveEndMinutes = leaveDate.end * 60;
            if (timeInMinutes >= leaveStartMinutes && timeInMinutes < leaveEndMinutes) {
                return; // Do nothing
            }
        }

        // Prevent creating a new event if `availability` is undefined or entire day is unavailable
        if (!availability || availability.unavailable) {
            return; // Do nothing
        }

        // Prevent creating an event if the selected time is outside the available hours
        const startTime = moment(availability.start, 'HH:mm:ss');
        const endTime = moment(availability.end, 'HH:mm:ss');
        if (timeInMinutes < startTime.hours() * 60 + startTime.minutes() ||
            timeInMinutes >= endTime.hours() * 60 + endTime.minutes()) {
            return; // Do nothing
        }

        // Proceed with the normal flow if the slot is within available hours
        setSelectedEvent(null);
        setFormData({
            title: '',
            start: moment(slotInfo.start).format('YYYY-MM-DDTHH:mm'),
            end: moment(slotInfo.end).format('YYYY-MM-DDTHH:mm'),
            patientName: '',
            purpose: '',
            injectorId: slotInfo.resourceId,
            bookingStatus: 'NOT_CONFIRMED'
        });
        setShowModal(true);
    };

    const TimeSlotWrapper = ({ children, value, resource }) => {
        const props = slotPropGetter(value, resource);
        return React.cloneElement(children, props);
    };

    const CustomResourceHeader = ({ label, index }) => {
        const backgroundColor = index % 2 === 0 ? '#64b0ee' : '#fbe3e3'; // Alternate background color

        return (
            <div style={{
                padding: '10px',
                borderRight: '1px solid #ccc',
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor
            }}>
                {label}
            </div>
        );
    };

    const addBreakHour = () => {
        setOpenAddBreakDialog(true);
    };

    const viewTodaySchedule = () => {
        setOpenViewSchedule({ action: true, injectorData: injectorData });
    };

    return (
        <div className="container-fluid" style={{ marginTop: "6%" }}>
            <Navbar />
            {/* <material.Paper elevation={3} sx={{ p: 3 }}> */}
            <div className="row">
                <div className="col-12">
                    <span className="float-end">
                        <material.Button
                            variant="contained"
                            sx={{ textTransform: "none", mr: 1 }}
                            size="small"
                            onClick={addBreakHour}
                            startIcon={<material.AddIcon />}
                        >
                            Add Break Hour
                        </material.Button>
                        <material.Button
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            size="small"
                            onClick={viewTodaySchedule}
                            startIcon={<material.VisibilityIcon />}
                        >
                            View Today Schedule
                        </material.Button>
                    </span>
                </div>
                <div className="col-12">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 670, cursor: "pointer" }}
                        onSelectEvent={handleSelectEvent}
                        selectable
                        onSelectSlot={handleSelectSlot}
                        resources={injectorData}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        defaultView={Views.WEEK}
                        min={new Date(new Date().setHours(6, 0, 0, 0))}
                        max={new Date(new Date().setHours(22, 0, 0, 0))}
                        views={{
                            day: true,
                            week: true,
                            month: true,
                            agenda: true,
                            work_week: true,
                        }}
                        eventPropGetter={(event) => ({
                            className: event.className,
                        })}
                        components={{
                            resourceHeader: CustomResourceHeader,
                            timeSlotWrapper: TimeSlotWrapper,
                        }}
                    />
                </div>
            </div>
            {/* </material.Paper> */}
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
            <CreateAppointment
                showModal={showModal}
                setShowModal={setShowModal}
                selectedEvent={selectedEvent}
                formData={formData}
                getAllScheduleData={getAllScheduleData}
            />
            <ViewAppointment
                openViewAppointment={openViewAppointment}
                setOpenViewAppointment={setOpenViewAppointment}
                selectedEvent={selectedEvent}
                getAllScheduleData={getAllScheduleData}
            />
            <ViewTodayScheduleData
                openViewSchedule={openViewSchedule}
                setOpenViewSchedule={setOpenViewSchedule}
            />
            <AddBreakHourDialog
                openAddBreakDialog={openAddBreakDialog}
                setOpenAddBreakDialog={setOpenAddBreakDialog}
                getAllScheduleData={getAllScheduleData}
                getAllInjectorByClinicId={getAllInjectorByClinic}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(Appointment);